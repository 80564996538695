import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Gallery from "../components/content/Gallery"
import SectionTitle from "../components/SectionTitle"
import PageWrapper from "../components/layout/PageWrapper"

const GalleryPage = () => (
  <Layout>
    <SEO
      title="Fireplace installation gallery"
      description="View Fire Skilled recent fireplace and stoves installations"
    />
    <PageWrapper>
      <SectionTitle
        title="Fireplace and stoves selection"
        strapline="View selection of our recent works"
      />
      <Gallery />
    </PageWrapper>
  </Layout>
)

export default GalleryPage
