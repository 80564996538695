import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { sortQueryData } from "../../helpers/query"

const query = graphql`
  {
    images: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(quality: 64, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const Gallery = () => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const data = useStaticQuery(query)

  const images = sortQueryData(data).map(item => {
    const image = item.node.childImageSharp.fluid
    return image.src
  })

  const thumbnails = sortQueryData(data).map(item => {
    const image = item.node.childImageSharp.fluid
    return image
  })

  return (
    <Wrapper>
      {thumbnails.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setPhotoIndex(index)
            setIsOpen(true)
          }}
        >
          <Img fluid={item} className="gallery-img" />
        </div>
      ))}

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          enableZoom={false}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Wrapper>
  )
}

export default Gallery

const Wrapper = styled.main`
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill);
  column-gap: 1.25rem;
  row-gap: 1.25rem;
  padding-bottom: var(--spacer-bottom);
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .gallery-img {
    height: 220px;
    cursor: pointer;
    transition: var(--transition);
  }
  .gallery-img:hover {
    opacity: 0.75;
  }
`
